<template>
  <div id="printThis">
    <mds-data-table :header-configs="headers" :row-data="historyData" v-if="!showModal" style="margin-top: 3%;">
          <template v-slot:body-cell="cellData">
            <template v-if="cellData.headerConfig.fieldName === 'note'">
              <a
                class="noteATag"
                href="#"
                style="position: relative;"
                :title="cellData.rowData.note"
              >
                <span
                  class="noteIdicator"
                  v-if="cellData.rowData.inputternote || cellData.rowData.note"
                ></span>
                <mds-icon
                  :id="'dataPoint' + cellData.rowData.datapointid"
                  name="document"
                >
                </mds-icon>
              </a>
            </template>
          </template>
        </mds-data-table>
    <mds-modal
    class="data-point-history-modal"
      :title="historyData[0].entityname + ' - ' + historyData[0].datapointname"
      :width="'1200px'"
      v-model="toggle"
     v-if="showModal"
     :actionRequired="true"
    >
      <div id="printThisTable" style="margin-top:16px;">
        <mds-data-table :header-configs="headers" :row-data="historyData">
          <template v-slot:body-cell="cellData">
            <template v-if="cellData.headerConfig.fieldName === 'note'">
              <a
                class="noteATag"
                href="#"
                style="position: relative;"
                :title="cellData.rowData.note"
              >
                <span
                  class="noteIdicator"
                  v-if="cellData.rowData.inputternote || cellData.rowData.note"
                ></span>
                <mds-icon
                  :id="'dataPoint' + cellData.rowData.datapointid"
                  name="document"
                >
                </mds-icon>
              </a>
            </template>
          </template>
        </mds-data-table>
      </div>
      <template v-slot:mds-modal-actions>
        <mds-button-container right-aligned>
          <mds-button
            title="Print"
            variation="icon-only"
            icon="print"
            @click="printHistory"
          ></mds-button>
          <mds-button
            title="Download"
            variation="icon-only"
            icon="download"
            @click="saveDataPointHistoryAsCSV"
          ></mds-button>
          <mds-button
            title="Close"
            @click="$emit('dataPointHistoryModalDismiss')"
            variation="icon-only"
            icon="remove"
          ></mds-button>
        </mds-button-container>
      </template>
    </mds-modal>
    <!-- 
                <mds-popover
                        title="Note"
                        @mds-popover-dismissed="cellData.rowData.shownote = false"
                        :triggered-by="'dataPoint' + cellData.rowData.datapointid"
                        :visible="cellData.rowData.shownote"
                        auto-position
                        position="bottom-left"
                    >
                        <template
                        v-if="false"
                        v-slot:mds-popover-actions
                        >
                        <mds-button-container>
                            <mds-button
                            variation="secondary"
                            size="small"
                            
                            >Discard Changes</mds-button
                            >
                            <mds-button
                            variation="primary"
                            size="small"
                            >Save</mds-button
                            >
                        </mds-button-container>
                        </template>
                        <mds-textarea
                        hidden-label
                        label="Add Note"
                        placeholder="Add Note"
                        :value="cellData.rowData.note"
                        readonly
                        ></mds-textarea>
                    </mds-popover> -->
  </div>
</template>
<script>
import MdsModal from "@mds/modal";
import { MdsDataTable } from "@mds/data-table";
import MdsIcon from "@mds/icon";
import { MdsButton, MdsButtonContainer } from "@mds/button";
import { downloadDatapointHistoryAsCsv } from "../../services/add_investment_service.js";
import { mapGetters } from "vuex";
// import MdsPopover from "@mds/popover";
// import MdsTextarea from "@mds/textarea";
export default {
  components: {
    MdsModal,
    MdsDataTable,
    MdsIcon,
    MdsButtonContainer,
    MdsButton,
    // MdsPopover,
    // MdsTextarea
  },
  props: {
    historyData: {
      type: Array,
    },
    selectedDataPointDetails: {
      required: true,
    },
    showModal:{
      type:Boolean,
      default:true
    },
    selectedEntityId:{
      type:[String, Number]
    }
  },
  data() {
    return {
      toggle: true,
      rows: this.historyData,

      headers: [
        {
          fieldName: "value",
          text: "New",
          width: "15%",
        },
        {
          fieldName: "previousvalue",
          text: "Old",
          width: "15%",
        },
        {
          fieldName: "changedbyuser",
          text: "Changed By",
          width: "25%",
        },
        {
          fieldName: "changeddate",
          text: "Changed Date",
          width: "25%",
        },
        {
          fieldName: "note",
          text: "Note",
          width: "10%",
        },
        {
          fieldName: "status",
          text: "Status",
          width: "15%",
        },
        {
          fieldName: "validatedbyuser",
          text: "Validated By",
          width: "25%",
        },
        {
          fieldName: "validateddate",
          text: "Validated Date",
          width: "25%",
        },
      ],
      list: [
        {
          groupName: " Client Login",
          roleID: 1,
          roleName: "View Own Profile",
          roleChecked: false,
        },
        {
          groupName: " Client Login",
          roleID: 1,
          roleName: "View Own Invoices",
          roleChecked: false,
        },
        {
          groupName: "Client Management",
          roleID: 1,
          roleName: "View Clients",
          roleChecked: true,
        },
        {
          groupName: "Client Management",
          roleID: 1,
          roleName: "Create Client",
          roleChecked: false,
        },
      ],
    };
  },
  computed:{
        ...mapGetters("entity", ["getSelectedEntity"])
  },
  mounted() {
    this.groups();
    // if(!this.showModal){
    //   this.printScreen()
    // }
  },
  methods: {
    // printScreen(){
    //   // window.print();
    //   // this.$router.push('/addinvestment')
    //    let elementHtml = document.getElementById("printThis").innerHTML;
    //    let elementStyle = ''
    //    for (const node of [...document.querySelectorAll('link[rel="stylesheet"], style')]) {
    //           elementStyle += node.outerHTML;
    //         }
    //         console.log(elementHtml);
    //         console.log(elementStyle);
    //   this.$emit("openPrintWindow");
    // },
    groupBy(array, key) {
      const result = {};
      array.forEach((item) => {
        if (!result[item[key]]) {
          result[item[key]] = [];
        }
        result[item[key]].push(item);
      });
      return result;
    },
    printHistory() {
      var element = document.getElementById("printThisTable").innerHTML;
       let elementStyle = ''
       for (const node of [...document.querySelectorAll('link[rel="stylesheet"], style')]) {
              elementStyle += node.outerHTML;
            }
      this.$emit("clickPrintHistory", element,elementStyle);
    },
    groups() {
      var list = this.groupBy(this.list, "groupName");
      console.log(list);
    },
   async saveDataPointHistoryAsCSV() {
     var entityId=''
     if(this.selectedEntityId != undefined){
       entityId= this.selectedEntityId;
     }else{
       entityId = this.getSelectedEntity.entityid;
     }
       
      var dataPointId = this.selectedDataPointDetails.datapointid;
      var arrayIndex = this.selectedDataPointDetails.arrayindex;
     await downloadDatapointHistoryAsCsv(entityId,dataPointId,arrayIndex);
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../../assets/css/styles.scss";
.historySubHeads {
  @include mds-body-text-m();
  margin: 0;
  font-weight: bold;
}
.noteIdicator {
  height: 10px;
  width: 10px;
  background-color: #0077cf;
  border-radius: 50%;
  display: inline-block;
  margin-left: 15px;
  position: absolute;
}
.noteATag:focus {
  box-shadow: none;
  outline: none;
}
</style>

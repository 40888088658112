<template>
  <div class="task_component" :class="{isFilterOpen: checkFilterOpen}">
    <mds-layout-grid>
      <mds-row style="margin: 0">
        <div
          :class="[{ showFilterLeftBlock: showFilterPanel }, 'leftBlock']"
          :style="cssVariables"
        >
            <div>
                <div>
                     <h2 class="template-header">Activity History</h2>
                </div>
                <mds-data-table
                  class="task-table-component"
                  show-sortable
                  :header-configs="tableHeader"
                  :row-data="tableData"
                  row-hover
                  @mds-data-table-sort-change="sortColumnsOnClick($event)"
                >
                  <template v-slot:body-cell="cellData">
                    <template v-if="cellData.colIndex === 0" >
                        <span @click="onRowClick(cellData)" class="no-highlight" style="cursor: pointer;">
                            {{cellData.rowData[cellData.headerConfig.fieldName]}}
                        </span>
                    </template>
                    <template v-if="cellData.headerConfig.fieldName == 'isin'">
                        <span v-if="cellData.rowData[cellData.headerConfig.fieldName] != null">
                           {{cellData.rowData[cellData.headerConfig.fieldName]}}
                        </span>
                        <span v-else>
                            -
                        </span> 
                    </template>
                    <template v-if="cellData.headerConfig.fieldName == 'value'">
                      <span v-if="cellData.rowData.datatypeid == dateType">
                       {{checkDataType(cellData.rowData)}}
                      </span>
                      <span v-else>
                            {{cellData.rowData[cellData.headerConfig.fieldName]}}
                        </span>
                    </template>
                    <template v-if="cellData.headerConfig.fieldName == 'history'">
                        <span :title="cellData.rowData[cellData.headerConfig.fieldName]" v-if="cellData.rowData[cellData.headerConfig.fieldName] == 'Pending'">
                            <!-- <mds-icon name="object-plus"></mds-icon> -->
                             <mds-icon  class="pendingApprovalIcon" size="small" name="alert"></mds-icon>
                        </span>
                        <span :title="cellData.rowData[cellData.headerConfig.fieldName]" v-else>
                            <mds-icon class="green-color-icon" name="check"></mds-icon>
                        </span> 
                    </template>
                    <template v-if="cellData.headerConfig.fieldName == 'updatedat'">
                        <span v-if="cellData.rowData[cellData.headerConfig.fieldName] != null">
                            {{convertDate(cellData.rowData[cellData.headerConfig.fieldName])}}
                        </span>
                        <span v-else>
                            {{cellData.rowData[cellData.headerConfig.fieldName]}}
                        </span>
                    </template>
                  </template>
                </mds-data-table>
                <mds-pagination
                  :class="[
                    showFilterPanel === true
                      ? 'move-pagination-show-info'
                      : '',
                    'pagination-component',
                  ]"
                  :key="paginationComponentKey"
                  v-if="tableData.length > 0"
                  :page="pageNumber"
                  show-items-info 
                  show-items-select
                  :total-items="totalListItems"
                  :pageSize="pageSize"
                  :pageSizes="[10, 20, 50]"
                  @mds-pagination-page-changed="paginateTable"
                  style="margin-top:10px"
                ></mds-pagination>
                <div >
                 <mds-empty-state
                    v-if="tableData.length == 0"
                    class="no-results-message"
                    size="medium"
                    title="No results matched"
                    message="We weren't able to match any results with your current selections. Please adjust your filter criteria and try again."
                ></mds-empty-state>
                </div>
            </div>
        </div>

        <div :class="[ { showFilterRightBlock: showFilterPanel },'rightBlock',]" :style="cssVariables">
            <transition-group name="fade" class="group-4">
            <filter-panel
              :key="checkBoxFilterKey"
              :filterData="filterData"
              searchInputLable=""
              searchInputPlaceHolder="Search by entity or ISIN"
              :showSearchInput="true"
              :searchInputText="filterPanelSearchInputText"
              :isFilterPanelOpen="showFilterPanel"
              :hideFilterPanelProp="hideFilterPanel"
              :selectedEntity="selectedEntityType"
              :setExactHeightForScroll="true"
              componentName="activityHistory"
              @onFilterChange="checkBoxChange"
              @showFilterPanel="openFilterPannel"
              @hideFilterPanel="closeFilterPanel"
              @multiSelectFilterChange="multiSelectFilterChange"
              @clearAllFilters="clearAllFilters"
              @dateRange="ondateRangeApplied"
              @filterEvent="searchFilterEvent"
              @clearInputFilter="clearSearchInput"

            ></filter-panel>
          </transition-group>
        </div>
      </mds-row>
    </mds-layout-grid>
    <datapoint-history-component @dataPointHistoryModalDismiss="dataPointHistoryModalDismiss" v-if="showPopup" :historyData="activityHistoryPopupData" @clickPrintHistory="clickPrintHistory" :selectedDataPointDetails='selectedDataPointDetails' :selectedEntityId="historyPopupEntityId"></datapoint-history-component>
    <loader-component v-if="displayLoader"></loader-component>
  </div>
</template>
<script>
import { MdsLayoutGrid, MdsRow } from "@mds/layout-grid";
import { MdsDataTable } from "@mds/data-table";
import { dateFormatterMixin } from "../../../../mixins/date.js";
import MdsIcon from '@mds/icon'
import LoaderComponent from '../../../ui_component/loaderComponent.vue';
import MdsPagination from "@mds/pagination";
import FilterPanel from '../../../common_components/FilterPanel.vue';
import DatapointHistoryComponent from '../../../common_components/DatapointHistoryComponent.vue' ;
import MdsEmptyState from "@mds/empty-state";
import Enums from '../../../../config/Enums.json'
export default {
   mixins: [dateFormatterMixin],
  components: {
    MdsLayoutGrid,
    MdsRow,
    LoaderComponent,
    MdsPagination,
    FilterPanel,
    MdsDataTable,
    MdsIcon,
    DatapointHistoryComponent,
    MdsEmptyState
  },
  props: {
    menuwidth: {
      type: String,
    },
    displayLoader: {
      type: Boolean,
      default: false,
    },
    tableHeader:{
       type: Array,
    },
    tableData:{
       type: Array,
    },
    pageNumber:{
        default:1
    },
    pageSize:{
        default:10
    },
    totalItems:{
        default:0
    },
    filterData:{
       type: Array,
    },
    selectedEntityType:{
      default:1
    },
    searchEntityISINText:{
      default:''
    },
    showDataPointHistory: {
      type: Boolean,
      default: false,
    },
    historyPopupData:{
       type: Array,
    },
    selectedDataPointDetails:{
      type:Object
    },
    selectedEntityId:{
      type:[String, Number]
    },
    paginationComponentKey: {
            type: Number
        }
  },
  data() {
    return {
      dateType:'',
      checkBoxFilterKey:1,
      filterPanelSearchInputText: "",
      showFilterPanel: false,
      hideFilterPanel: true,
      showPopup:false,
      activityHistoryPopupData:[],
      historyPopupEntityId:'',
      checkFilterOpen: false
    };
  },
  computed: {
    cssVariables() {
      return {
        "--menuWidth": this.menuwidth,
      };
    },
     totalListItems() {
            return this.totalItems;
        },
  },
  watch:{
    showDataPointHistory(val){
      if(val){
        this.showHistoryPopup()
      }else{
        this.closeHistoryPopup()
      }
    }
  },
  mounted(){
    this.dateType=Enums.dataTypes.Date
  },
  methods: {
    checkDataType(rowData){
      if(rowData.value){
        return this.convertAdditionalSearchDate(rowData.value)
      }else{
        return rowData.value
      }
    },
    showHistoryPopup(){
      this.showPopup=true
      this.activityHistoryPopupData=this.historyPopupData
      this.historyPopupEntityId=this.selectedEntityId
    },
    closeHistoryPopup(){
      this.showPopup=false
      this.activityHistoryPopupData=[]
      this.historyPopupEntityId=''
    },
    dataPointHistoryModalDismiss(){
      this.$emit("dismissDataPointHistoryPopup")
    },
    clickPrintHistory(element,elementStyle){
      this.$emit("printHistory",element,elementStyle)
    },
    onRowClick(cellData){
      this.$emit("onRowClicked",cellData)
    },
    closeFilterPanel() {
      this.checkFilterOpen = false;
      this.showFilterPanel = false;
    },
    openFilterPannel() {
      this.checkFilterOpen = true;
      this.showFilterPanel = true;
    },
    checkBoxChange(entityType){
      this.$emit("onEntityTypeChange",entityType)
    },
    sortColumnsOnClick(event) {
      this.$emit("sortColumns", event);
    },
    async paginateTable(arg) {
      this.$emit("onPaginationChange",arg)
    },
    async multiSelectFilterChange(event,value,headerTypeDetails){
      this.$emit("onmultiSelectFilterChange", event, value, headerTypeDetails);
    },
    async clearAllFilters(){
      this.$emit("clearAllFilters")
    },
    ondateRangeApplied(fromDate, toDate){
      this.$emit("dateRangeChange",fromDate, toDate)
    },
    searchFilterEvent(searchValue){
      this.$emit("filterEvent",searchValue)
    },
    clearSearchInput(){
      this.$emit("clearSearchInputField")
    }
  },
};
</script>
<style lang="scss" scoped>
@import "../../../../assets/css/styles.scss";

.isFilterOpen {width: 82%}
.leftBlock {
  max-width: 94%;
  position: relative;
  overflow: hidden;
  margin-left: 16px;
  margin-right: 71px;
  width: -webkit-fill-available;
}
.rightBlock {
  width: 55px;
  padding: 0;
  margin-left: calc(100vw - 55px - var(--menuWidth));
  min-height: 100vh;
  border: 1px solid #979797;
  border-top: 1px solid #cccccc;
  position: fixed;
  z-index: 1;
  background-color: white;
}
.showFilterLeftBlock {
  max-width: 94%;
  margin-left: 16px;
  margin-right: 71px;
}
.showFilterRightBlock {
  width: 292px;
  margin-left: calc(100vw - 292px - var(--menuWidth));
  position: fixed;
  z-index: 1;
}
.no-results-message {
    margin: auto;
    min-width: 50%;
    margin-top: 16px;
}
.template-header {
  @include mds-level-2-heading();
  margin: 16px 0px 20px 0px;
}
.task_component .mds-tabs___VueMDS3Demo {
  margin-bottom: 16px;
}
.link {
  @include mds-link($in-table: true);
}

html {
  @media screen and (min-width: 1280px) {
    overflow-x: hidden;
  }
}
.task-table-component {
  max-height: 560px;
  overflow: auto;
}
.no-highlight {
  color: black;
}
.move-pagination-show-info::v-deep .mds-pagination__show-items___VueMDS3Demo {
  margin-right: calc(292px - 55px);
}
.pagination-component {
    position: fixed;
    width: calc(100vw - 88px - var(--menuWidth));
    bottom: 0;
}
.pendingApprovalIcon{
   @include mds-icon-color(#ef7622);
}
.green-color-icon {
    @include mds-icon-color(#00AF41);
}
.task_component .mds-icon___VueMDS3Demo{
    height: 15px;
    width: 15px;
}
</style>

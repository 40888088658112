<template>
    <div>
        <activity-history-component
            :menuwidth="menuwidth" 
            :displayLoader="displayLoader"
            :tableHeader="activityListTableHeaders"
            :tableData="activityHistoryListTableData"
            :pageNumber="paginationPageNumber"
            :pageSize="paginationpageSize"
            :paginationComponentKey="paginationComponentKey"
            :totalItems="paginationtotalItems"
            :filterData="filterObject"
            :selectedEntityType="selectedEntityType"
            :searchEntityISINText="searchInputTextFilter"
            @onPaginationChange="onPaginationChange"
            @onmultiSelectFilterChange="multiSelectFilterChange"
            @onEntityTypeChange="onEntityTypeChange"
            @dateRangeChange="onChangedDateRangeChange"
            @clearAllFilters="clearAllFilters"
            @filterEvent="searchTextFilterEvent"
            @clearSearchInputField="onClearOfSearchInput"
            @sortColumns="onSortColumnsClick"
            @onRowClicked="fetchRowHistory"
            :key="HistoryComponentKey"
            :showDataPointHistory="showDataPointHistoryPopup"
            :historyPopupData="dataPointHistoryData"
            :selectedDataPointDetails="selectedDataPointDetails"
            :selectedEntityId="selectedEntityId"
            @dismissDataPointHistoryPopup="dismissDataPointHistoryPopup"
            @printHistory="printHistory"
        >
        </activity-history-component>
    </div>
</template>
<script>
import store from "@/store";
import get from "lodash/get";
import ActivityHistoryComponent from '../components/ActivityHistoryComponent.vue';
import { ACTIVITYHISTORY_ACTIONS } from "@/store/modules/activityHistory/contants";
import { mapState,mapActions,mapGetters} from "vuex";
import {getDatapoints} from '../../../../services/bulk_edit_service.js';
import { getDataPointHistory} from "../../../../services/add_investment_service";
import { REPORTING_ACTIONS } from "@/store/modules/reporting/constants";
import Enums from '../../../../config/Enums.json'
import { dateFormatterMixin } from '../../../../mixins/date.js';
export default {
    components: { ActivityHistoryComponent },
    mixins: [ dateFormatterMixin ],
    props: {
        menuwidth: {
            type: String,
        },
    },
     beforeCreate() {
        this.$store = store;
    },
    data(){
        return{
            currentDate:'',
            last90DayDate:'',
            paginationComponentKey: 0,
            selectedEntityId:'',
            selectedDataPointDetails:{},
            dataPointHistoryData:[],
            showDataPointHistory:false,
            showDataPointHistoryPopup:false,
            HistoryComponentKey:0,
            onPageLoad:true,
            sortOrder:-1,
            sortedColName:'updatedat',
            sortOrderColumnName:'updatedat',
            searchInputTextFilter:"",
            selectedFromDate:"",
            selectedToDate:"",
            displayLoader:false,
            showLoader:false,
            paginationPageNumber:1,
            paginationpageSize:10,
            paginationtotalItems:0,
            entityTypeList:[],
            selectedEntityType:1,
            selectedDataPointList:[],
            selectedUpdatedUserList:[],
            activityHistoryPayload:{
                "Page": 1,
                "PageSize": 10,
                "IsAscending": true,
                "searchDatapoint":'',
                "searchEntityType":this.selectedEntityType,
                "fromDate":'',
                "toDate":'',
                "UpdatedBy":'',
                "searchEntity":'',
                "orderByColumn":''
            },
            activityListTableHeaders: [
                    {
                    fieldName: "entity",
                    text: "Entity",
                    align: "left",
                    width: "15%",
                    sortable: true,
                    
                    },
                    {
                    fieldName: "entitytype",
                    text: "Entity Type",
                    align: "left",
                    width: "8%",
                    sortable: true,
                    },
                    {
                    fieldName: "isin",
                    text: "ISIN",
                    align: "left",
                    width: "9%",
                    sortable: true,
                    },
                    {
                    fieldName: "datapoint",
                    text: "Data Point",
                    align: "left",
                    width: "13%",
                    sortable: true,
                    },
                    {
                    fieldName: "updatedby",
                    text: "Updated By",
                    align: "left",
                    width: "15%",
                    sortable: true,
                    },
                    {
                    fieldName: "updatedat",
                    text: "Last Updated",
                    align: "left",
                    width: "10%",
                    sortable: true,
                    sorted: -1,
                    },
                    {
                    fieldName: "value",
                    text: "Value",
                    align: "left",
                    width: "14%",
                    sortable: true,
                    },
                    {
                    fieldName: "history",
                    text: "Status",
                    align: "left",
                    width: "5%",
                    },
                    
                ],
                activityHistoryListTableData:[],
                filterObject:[
                    {
                    filterHeader:"Entity Type",
                    selectionType:"single",
                    showSearchInputField:false,
                    searchText:'',
                    filterList:[]
                    },{
                    filterHeader:"Data Point",
                    selectionType:"multiple",
                    showSearchInputField:true,
                    searchText:'',
                    filterList:[]
                    },
                    {
                    filterHeader: "Date Range",
                    selectionType: "daterange",
                    filterList: [],
                    },
                    {
                    filterHeader:"Updated By",
                    selectionType:"multiple",
                    showSearchInputField:true,
                    searchText:'',
                    filterList:[]
                    },
                ]
        }
    },
     watch:{
        showLoader(val){
            this.displayLoader=val
        },
        selectedFromDate(){
            this.onFromDateChange()
        },
        selectedToDate(){
            this.onToDateChange()
        },
        showDataPointHistory(val){
            this.showDataPointHistoryPopup=val
        }
    },
    computed:{
        ...mapGetters(["cachedDispatch"]),
        ...mapGetters("entity", ["getEntityTypes"]),
        ...mapState({
            activityHistoryList(state) {
                return get(state, "activityHistory.activityHistoryList.activityHistoryList", []);
            },
            activityHistoryListLoader(state) {
                return get(state, "activityHistory.activityHistoryList.__loading__", false);
            },
            activityHistoryListError(state) {
                return get(state, "activityHistory.activityHistoryList.__error__", null);
            },
            getAllUserList(state) {
                return get(
                    state,
                    "reportingModule.userList.userList",
                    []
                );
            },
        }),
       
    },
    async created(){
        this.getDateRangeFilter()
    },
    async mounted(){
        this.currentDate=new Date().toISOString();
        this.last90DayDate=this.getSubtractedDate(new Date(), 90);
        this.selectedFromDate=this.last90DayDate
        this.selectedToDate=this.currentDate
        this.showLoader=true
        this.onPageLoad=false
        await this.fetchActivityHistoryDetails()
        await this.getAttributesData()
        await this.fetchWorkflowAllUsersList()
        if (this.getEntityTypes.length == 0) {
            await this.fetchEntityTypes();
            this.entityTypeList = this.getEntityTypes;
        }else {
            this.entityTypeList = this.getEntityTypes;
        }
        await this.addDataToFilterObject(false);
        this.showLoader=false
    },
    methods:{
    ...mapActions("entity", ["fetchEntityTypes"]),
    getDateRangeFilter(){
          
            this.filterObject[2].filterHeader = "Date Range";
            this.filterObject[2].selectionType = "daterange";
            const selctedFromDate = this.selectedFromDate ? new Date(this.selectedFromDate) : this.selectedFromDate;
            const selctedToDate = this.selectedToDate ? new Date(this.selectedToDate) : this.selectedToDate
            
              this.filterObject[2].filterList = [
                {
                    id: 1,
                    isdisabled: false,
                    lable: "from date",
                    value: selctedFromDate,
                    minMaxDates: {
                        min: new Date(2000, 0, 1),
                        max: new Date(2049, 11, 31),
                    },
                },
                {
                    id: 2,
                    isdisabled: false,
                    lable: "to date",
                    value: selctedToDate,
                    minMaxDates: {
                        min: new Date(2000, 0, 1),
                        max: new Date(2049, 11, 31),
                    },
                },
            ]
        },
    onFromDateChange(){
            for(let i=0;i<this.filterObject.length;i++){
                if(this.filterObject[i].filterHeader == "Date Range"){
                    const selctedFromDate = this.selectedFromDate ? new Date(this.selectedFromDate) : this.selectedFromDate;
                    this.filterObject[i].filterList[0].value=selctedFromDate;
                }
            }
         },
    onToDateChange(){
        for(let i=0;i<this.filterObject.length;i++){
        if(this.filterObject[i].filterHeader == "Date Range"){
            const selctedToDate = this.selectedToDate ? new Date(this.selectedToDate) : this.selectedToDate
            
            this.filterObject[i].filterList[1].value=selctedToDate;
        }
    }

    },
    async onPaginationChange(arg){
        this.paginationPageNumber=arg.page
        this.paginationpageSize=arg.pageSize
        this.paginationComponentKey++
        this.showLoader=true
        await this.fetchActivityHistoryDetails()
        this.showLoader=false
    },
    async fetchActivityHistoryDetails(){
        let searchDatapointString=''
        let searchUpdatedByUserSting=''
        if(this.selectedDataPointList.length >0){
            for(let i=0;i<this.selectedDataPointList.length;i++){
                if(i==0){
                    searchDatapointString=this.selectedDataPointList[i].toString()
                }else{
                    searchDatapointString=searchDatapointString+','+this.selectedDataPointList[i]
                }
            }
        }
        if(this.selectedUpdatedUserList.length >0){
            for(let i=0;i<this.selectedUpdatedUserList.length;i++){
                if(i==0){
                    searchUpdatedByUserSting=this.selectedUpdatedUserList[i]
                }else{
                    searchUpdatedByUserSting=searchUpdatedByUserSting+','+this.selectedUpdatedUserList[i]
                }
            }
        }
        let data = this.activityHistoryPayload
        data.Page=this.paginationPageNumber
        data.PageSize=this.paginationpageSize
        data.searchDatapoint=searchDatapointString
        data.UpdatedBy=searchUpdatedByUserSting
        data.orderByColumn=this.sortOrderColumnName
        if(this.selectedFromDate){
            data.fromDate=this.selectedFromDate
        }else{
            data.fromDate=undefined
        }
        if(this.selectedToDate){
            data.toDate=this.selectedToDate
        }else{
            data.toDate=undefined
        }
        if(this.selectedEntityType == 0){
            data.searchEntityType=undefined
        }else{
           data.searchEntityType=this.selectedEntityType
        }
        if(this.searchInputTextFilter){
            data.searchEntity=this.searchInputTextFilter
        }else{
            data.searchEntity=''
        }
        if(this.sortOrder == 1){
            data.IsAscending = true
        }else if(this.sortOrder == -1){
            data.IsAscending = false
        }
        // 
        // 
        await this.$store.dispatch(ACTIVITYHISTORY_ACTIONS.FETCH_ACTIVITY_HISTORY_LIST,{
                    data,
                })
                if(this.activityHistoryListError == null){
                    this.paginationPageNumber=this.activityHistoryList.pagenumber
                    this.paginationpageSize=this.activityHistoryList.pagesize
                    this.paginationtotalItems=this.activityHistoryList.totalitems
                    this.activityHistoryListTableData=this.activityHistoryList.model.list
                    for(let i=0;i<this.activityHistoryListTableData.length;i++){
                        this.activityHistoryListTableData[i]['id']=1
                    }
                }
    },
    addDataToFilterObject(isDisabled){
        this.filterObject[0].filterList=[];
        let allObject={
            lable:"All",
            id:0,
            isdisabled:isDisabled
        }
        this.filterObject[0].filterList.push(allObject)
    this.entityTypeList.forEach((entity) => {
        this.filterObject[0].filterList.push({
        lable:entity.entitytypedescription,
        id:entity.entitytypeid,
        isdisabled:isDisabled
        });
    });
    },
    async getAttributesData(){
    let response = await getDatapoints(this.selectedEntityType)
    if(!response.isError){
        // filterObject
        for(let i=0;i<this.filterObject.length;i++){
            if(this.filterObject[i].filterHeader == "Data Point"){
                this.filterObject[i].filterList=[]
                for(let j=0;j<response.data.length;j++){
                    if(response.data[j].datapointgroups == null){
                        let filterListObject={
                            lable:response.data[j].name,
                            id:response.data[j].id,
                            isChecked:false
                        }
                        this.filterObject[i].filterList.push(filterListObject)
                    }else{
                        for(let k=0;k<response.data[j].datapointgroups.length;k++){
                            let filterGroupListObject={
                                                lable:response.data[j].datapointgroups[k].name,
                                                id:response.data[j].datapointgroups[k].id,
                                                isChecked:false
                                            }
                            this.filterObject[i].filterList.push(filterGroupListObject)
                        }
                    }
                    
                }
            }
        }
        // this.checkBoxFilterKey+=1
    }
    },
    async multiSelectFilterChange(event,value,headerTypeDetails){
        this.paginationPageNumber=1
         this.paginationComponentKey++
        for(let i=0;i<this.filterObject.length;i++){
                if(this.filterObject[i].filterHeader == headerTypeDetails){
                    for(let j=0;j<this.filterObject[i].filterList.length;j++){
                        if(this.filterObject[i].filterList[j].id == value.id){
                            this.filterObject[i].filterList[j].isChecked = event
                            break;
                        }
                    }
                    
                }
            }
        if(headerTypeDetails == "Data Point"){
            
            if(event){
                this.selectedDataPointList.push(value.id)
            }else{
                if(this.selectedDataPointList.length != 0){
                    for(let i=0;i<this.selectedDataPointList.length;i++){
                        if(this.selectedDataPointList[i] == value.id){
                            this.selectedDataPointList.splice(i,1)
                            break;
                        }
                    }
                }
            }
            
        }else if(headerTypeDetails == "Updated By"){
            if(event){
                this.selectedUpdatedUserList.push(value.id)
            }else{
                if(this.selectedUpdatedUserList.length != 0){
                    for(let i=0;i<this.selectedUpdatedUserList.length;i++){
                        if(this.selectedUpdatedUserList[i] == value.id){
                            this.selectedUpdatedUserList.splice(i,1)
                            break;
                        }
                    }
                }
            }
        }
        // 
        this.showLoader=true
        await this.fetchActivityHistoryDetails()
        this.showLoader=false
          
    },
    async onEntityTypeChange(entityType){
        this.selectedEntityType=entityType
        this.selectedDataPointList=[]
        // this.selectedUpdatedUserList=[]
        this.paginationPageNumber=1
        this.paginationComponentKey++
        // this.sortOrder=-1
        // this.sortOrderColumnName='updatedat'
        this.showLoader=true
        await this.fetchActivityHistoryDetails()
        await this.getAttributesData()
        this.showLoader=false
    },
    async onChangedDateRangeChange(fromDate, toDate){
        this.selectedFromDate=fromDate.toLocaleDateString();
        this.selectedToDate=toDate.toLocaleDateString();
        this.paginationPageNumber=1
         this.paginationComponentKey++
        this.showLoader=true
        await this.fetchActivityHistoryDetails()
        this.showLoader=false
    },
    async clearAllFilters(){ 
        this.selectedFromDate= new Date(this.last90DayDate)
        this.selectedToDate=new Date(this.currentDate)
        this.selectedUpdatedUserList=[]
         for(let i=0;i<this.filterObject.length;i++){
                if(this.filterObject[i].selectionType == "multiple"){
                    for(let j=0;j<this.filterObject[i].filterList.length;j++){
                            this.filterObject[i].filterList[j].isChecked = false
                        
                    }
                    
                }
            }
            if(this.selectedEntityType==1){
                await this.onEntityTypeChange(1)
            }else{
                 this.selectedEntityType=1
            }
        // 

    },
    async fetchWorkflowAllUsersList(){
             await this.cachedDispatch(REPORTING_ACTIONS.FETCH_USER_LIST);
            let response = this.getAllUserList.data;
            for(let i=0;i<this.filterObject.length;i++){
                 if(this.filterObject[i].filterHeader == "Updated By"){
                     for(let j=0;j<response.length;j++){
                         let workFlowObject={
                                lable: response[j].email,
                                id: response[j].userid,
                                isChecked: false,
                            }
                            this.filterObject[i].filterList.push(workFlowObject)
                     }
                 }
             }
    },
    async searchTextFilterEvent(searchValue){
        this.searchInputTextFilter=searchValue
        this.paginationPageNumber=1
         this.paginationComponentKey++
        this.showLoader=true
        await this.fetchActivityHistoryDetails()
        this.showLoader=false
    },
    async onClearOfSearchInput(){
        this.searchInputTextFilter=''
        this.paginationPageNumber=1
         this.paginationComponentKey++
        this.showLoader=true
        await this.fetchActivityHistoryDetails()
        this.showLoader=false
    },
     /**This logic given in MDS documentaion so added in seperate function */
        executeSortingLogicForMdsTable(colName) {
            // if your tracked sort order is 0 (none), set it 1 (ascending)
            // if there is already an order being tracked inverse it
            this.sortOrder = this.sortOrder === 0 ? 1 : -this.sortOrder;

            // if the table was already sorted by a different column, then reset that column to 0 (none)
            if (this.sortedColName !== null && this.sortedColName !== colName) {
                for(let i=0;i<this.activityListTableHeaders.length;i++){
                    if(this.activityListTableHeaders[i].fieldName == this.sortedColName){
                        this.activityListTableHeaders[i].sorted = 0
                    }
                }
                this.sortOrder = -1;
            }

            // track the current column as the one being sorted on
            this.sortedColName = colName;
            for(let i=0;i<this.activityListTableHeaders.length;i++){
                    if(this.activityListTableHeaders[i].fieldName == this.sortedColName){
                        this.activityListTableHeaders[i].sorted = this.sortOrder
                    }
                }
        },
    async onSortColumnsClick(event){
        if(!this.onPageLoad){
           this.paginationPageNumber=1
           this.paginationComponentKey++
        //   this.sortOrder=event.sortOrder
          this.sortOrderColumnName=event.sortField
          await this.executeSortingLogicForMdsTable(event.sortField)
          this.showLoader=true
            await this.fetchActivityHistoryDetails()
            this.showLoader=false
        }
    },
    async fetchRowHistory(cellData){
        this.showLoader = true
        this.selectedDataPointDetails=cellData.rowData
        this.selectedEntityId=cellData.rowData.entityid
      let datapointHistoryResponse = await getDataPointHistory(cellData.rowData.entityid,cellData.rowData.datapointid,cellData.rowData.arrayindex) 
      this.showLoader = false
      if(datapointHistoryResponse.status == 200){
        this.dataPointHistoryData = datapointHistoryResponse.data
         for(var i=0;i<this.dataPointHistoryData.length;i++){
                this.dataPointHistoryData[i]["id"]=this.dataPointHistoryData[i].datapointid
                this.dataPointHistoryData[i]["shownote"] = false
                if(this.dataPointHistoryData[i].validateddate){
                this.dataPointHistoryData[i].validateddate = this.convertUTCDate(this.dataPointHistoryData[i].validateddate + "Z")
                }
                if(this.dataPointHistoryData[i].changeddate){
                 this.dataPointHistoryData[i].changeddate = this.convertUTCDate(this.dataPointHistoryData[i].changeddate)
                }
                if(this.dataPointHistoryData[i].datatype == Enums.dataTypes.Date){
                  this.dataPointHistoryData[i].value=this.convertAdditionalSearchDate(this.dataPointHistoryData[i].value)
                }
                if(this.dataPointHistoryData[i].validatedvaliditystateid == Enums.ValidityStates.NeedsApproval || this.dataPointHistoryData[i].validatedvaliditystateid == 0 || this.dataPointHistoryData[i].validatedvaliditystateid == null){
                  this.dataPointHistoryData[i]["status"] = "Pending"
                }else if(this.dataPointHistoryData[i].validatedvaliditystateid == Enums.ValidityStates.RejectedForEdit){
                   this.dataPointHistoryData[i]["status"] = "Rejected For Edit"
                }else if(this.dataPointHistoryData[i].validatedvaliditystateid == Enums.ValidityStates.Rejected){
                   this.dataPointHistoryData[i]["status"] = "Rejected"
                }else if(this.dataPointHistoryData[i].validatedvaliditystateid == Enums.ValidityStates.Approved){
                   this.dataPointHistoryData[i]["status"] = "Approved"
                }
            }
        this.showDataPointHistory =true
      }
    },
    dismissDataPointHistoryPopup(){
        this.showDataPointHistory = false
        this.dataPointHistoryData=[]
        this.selectedDataPointDetails={}
    },
    printHistory(element,elementStyle){
         const WinPrint = window.open('', '', 'left=0,top=0,width=1200,height=1000,toolbar=0,scrollbars=0,status=0');

          WinPrint.document.write(`<!DOCTYPE html>
          <html>
            <head>
              ${elementStyle}
              <style>
              .mds-data-table__header-cell___VueMDS3Demo{
                width:auto !important;
              }
              .mds-data-table__cell___VueMDS3Demo{
                overflow-wrap: anywhere;
              }
              </style>
            </head>
            <body>
              ${element}
            </body>
          </html>`);

          WinPrint.document.close();
          WinPrint.focus();
          WinPrint.print();
          WinPrint.close();
    }
    }
}
</script>